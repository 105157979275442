var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"wrapper"},[_c('menu',[_c('li',{staticClass:"footer-nav"},[_c('h4',[_vm._v("\n          "+_vm._s(_vm.$t('footer.services'))+"\n        ")]),_vm._v(" "),_c('ul',{staticClass:"footer-nav-list first"},[_c('div',{staticClass:"footer-nav-column"},[_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('bitcoin-wallet')},nativeOn:{"click":function($event){return _vm.$track('Clicked link bitcoin wallet', {
                    name: 'bitcoin wallet',
                    place: 'footer site',
                  })}}},[_vm._v("\n                "+_vm._s(_vm.$t('links.bitcoin_wallet'))+"\n              ")])],1),_vm._v(" "),_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('bitcoin-debit-card')},nativeOn:{"click":function($event){return _vm.$track('Clicked link prepaid card', {
                    name: 'prepaid card',
                    place: 'footer site',
                  })}}},[_vm._v("\n                "+_vm._s(_vm.$t('links.prepaid_card'))+"\n              ")])],1),_vm._v(" "),_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('buy-cryptocurrency')},nativeOn:{"click":function($event){return _vm.$track('Clicked link buy crypto', {
                    name: 'buy crypto',
                    place: 'footer site',
                  })}}},[_vm._v("\n                "+_vm._s(_vm.$t('links.buy_crypto'))+"\n              ")])],1),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_vm.links.b2b},on:{"click":function($event){return _vm.$track('Clicked link accept bitcoins', {
                    name: 'accept bitcoins',
                    place: 'footer site',
                  })}}},[_vm._v("\n                "+_vm._s(_vm.$t('links.accept_bitcoins'))+"\n              ")])])])])]),_vm._v(" "),_c('li',{staticClass:"footer-nav"},[_c('h4',[_vm._v("\n          "+_vm._s(_vm.$t('footer.info'))+"\n        ")]),_vm._v(" "),_c('ul',[_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('about')},nativeOn:{"click":function($event){return _vm.$track('Clicked link about company', {
                  name: 'about company',
                  place: 'footer site',
                })}}},[_vm._v("\n              "+_vm._s(_vm.$t('links.company'))+"\n            ")])],1),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_vm.links.api,"rel":"nofollow noopener","target":"_blank"},on:{"click":function($event){return _vm.$track('Clicked button api', {
                  name: 'api',
                  place: 'footer site',
                })}}},[_vm._v("\n              "+_vm._s(_vm.$t('links.api'))+"\n            ")])]),_vm._v(" "),_c('li',[_c('n-link',{attrs:{"to":"/legal","rel":"nofollow noopener","target":"_blank"},nativeOn:{"click":function($event){return _vm.$track('Clicked link user agreement', {
                  name: 'user agreement',
                  place: 'footer site',
                })}}},[_vm._v("\n              "+_vm._s(_vm.$t('links.legal'))+"\n            ")])],1)])]),_vm._v(" "),_c('li',{staticClass:"footer-nav"},[_c('h4',[_vm._v("\n          "+_vm._s(_vm.$t('footer.faq'))+"\n        ")]),_vm._v(" "),_c('ul',[_c('li',[_c('a',{attrs:{"href":_vm.links['What is Cryptopay?'],"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){return _vm.$track('Clicked button', {
                  name: 'What is Cryptopay?',
                  place: 'footer site',
                })}}},[_vm._v("\n              "+_vm._s(_vm.$t('links.what_is_cryptopay'))+"\n            ")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_vm.links['What is a Cryptopay card?'],"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){return _vm.$track('Clicked button', {
                  name: 'What is a Cryptopay card?',
                  place: 'footer site',
                })}}},[_vm._v("\n              "+_vm._s(_vm.$t('links.what_is_a_cryptopay_card'))+"\n            ")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_vm.links['How do I buy Bitcoins?'],"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){return _vm.$track('Clicked button', {
                  name: 'How do I buy Bitcoins?',
                  place: 'footer site',
                })}}},[_vm._v("\n              "+_vm._s(_vm.$t('links.how_do_i_buy_bitcoins'))+"\n            ")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_vm.links['How to Sell Bitcoins?'],"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){return _vm.$track('Clicked button', {
                  name: 'How to Sell Bitcoins?',
                  place: 'footer site',
                })}}},[_vm._v("\n              "+_vm._s(_vm.$t('links.how_to_sell_bitcoins'))+"\n            ")])])])]),_vm._v(" "),_c('li',{staticClass:"footer-nav"},[_c('h4',[_vm._v("\n          "+_vm._s(_vm.$t('footer.have_a_question'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"list6"},[_vm._v("\n          "+_vm._s(_vm.$t('footer.find_out_all_answers'))+"\n        ")]),_vm._v(" "),_c('a',{staticClass:"btn btn__secondary",attrs:{"href":_vm.links.help,"rel":"nofollow noopener","target":"_blank"},on:{"click":function($event){return _vm.$track('Clicked button helpdesk', {
              name: 'helpdesk',
              place: 'footer site',
            })}}},[_vm._v("\n          "+_vm._s(_vm.$t('links.helpdesk'))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"copyright"},_vm._l((_vm.$t('footer.about_cryptopay')),function(line){return _c('p',{key:line,domProps:{"textContent":_vm._s(line)}})}),0),_vm._v(" "),_c('div',{staticClass:"footer-bottom"},[_c('p',{staticClass:"t4 ff_regular"},[_vm._v("\n        © "+_vm._s(_vm.getYear)+", Cryptopay Ltd.\n      ")]),_vm._v(" "),_c('div',{staticClass:"footer-bottom-right"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":_vm.links.twitter,"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){return _vm.$track('Clicked button', {
                  name: 'twitter',
                  place: 'footer site',
                })}}},[_vm._v("\n              Twitter\n            ")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_vm.links.facebook,"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){return _vm.$track('Clicked button', {
                  name: 'facebook',
                  place: 'footer site',
                })}}},[_vm._v("\n              Facebook\n            ")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_vm.links.linkedin,"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){return _vm.$track('Clicked button', {
                  name: 'linkedin',
                  place: 'footer site',
                })}}},[_vm._v("\n              LinkedIn\n            ")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_vm.links.telegram,"target":"_blank","rel":"nofollow noopener"},on:{"click":function($event){return _vm.$track('Clicked button', {
                  name: 'telegram',
                  place: 'footer site',
                })}}},[_vm._v("\n              Telegram\n            ")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }