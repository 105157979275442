//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HeaderB2Switcher',
  data () {
    return {
      links: [
        {
          id: 'b2switcher-link-personal',
          text: 'Personal',
          href: '/',
          active: true,
        },
        {
          id: 'b2switcher-link-business',
          text: 'Business',
          href: 'https://business.cryptopay.me/',
          active: false,
          newTab: true,
        },
      ],
    }
  },
}
