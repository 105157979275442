export default {
  ico: 'https://ico.cryptopay.me',
  help: 'https://help.cryptopay.me',
  api: 'https://developers.cryptopay.me/',
  b2b: 'https://business.cryptopay.me/',

  // faq
  'What is a Cryptopay card?':
    'https://help.cryptopay.me/en/collections/98616-c-pay-prepaid-card-in-eea',
  'How do I buy Bitcoins?':
    'https://help.cryptopay.me/en/articles/3415075-how-do-i-buy-bitcoins',
  'How to Sell Bitcoins?':
    'https://help.cryptopay.me/en/articles/3415083-how-to-exchange-or-sell-crypto-and-fiat',
  'What is Cryptopay?':
    'https://help.cryptopay.me/en/articles/3414906-about-cryptopay',

  // social
  medium: 'https://medium.com/cryptopay',
  reddit: 'https://www.reddit.com/r/CryptopayOfficial/',
  twitter: 'https://twitter.com/cryptopay',
  facebook: 'https://facebook.com/cryptopayme',
  telegram: 'https://t.me/cryptopayme',
  linkedin: 'https://linkedin.com/company/cryptopay',

  // mobile apps
  'app-store': 'http://m.onelink.me/71f7b0ac',
  'google-play': 'http://m.onelink.me/90296683',

  // account
  'sign-in': `${process.env.CPAY_URL}/sign-in`,
  'sign-up': `${process.env.CPAY_URL}/sign-up`,
  'sign-out': `${process.env.CPAY_URL}/sign-out`,
  close_account: `${process.env.CPAY_URL}/settings/close_account`,
  accounts: `${process.env.CPAY_URL}/accounts`,
  accounts_withdrawal: `${process.env.CPAY_URL}/accounts?modal[name]=coinWithdrawalPopup`,
  verification: `${process.env.CPAY_URL}/verification`,

  terms_and_conditions:
    'https://files.cryptopay.me/share/cryptopay_ico_terms_and_conditions.pdf',
  rates_and_limits: 'https://files.cryptopay.me/share/rates_and_limits.pdf',
  'how-do-i-buy-bitcoins':
    'https://help.cryptopay.me/en/articles/3415075-how-do-i-buy-bitcoins',
  'how-do-i-sell-bitcoins':
    'https://help.cryptopay.me/en/articles/3415076-how-do-i-sell-bitcoins',
}
