var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'header-nav',
    {
      'header-nav--mobile-nav-opened': _vm.isMobileNavOpened,
    } ]},[_c('div',{staticClass:"header-nav__menu"},[_vm._l((_vm.externalLinksNav),function(link){return _c('a',{key:link.id,staticClass:"header-menu-link",attrs:{"href":link.to,"data-header-link-badge":link.badge || false},on:{"click":function($event){return _vm.$track(link.track_event, link.track_payload)}}},[_vm._v("\n      "+_vm._s(link.text)+"\n    ")])}),_vm._v(" "),_vm._l((_vm.nav),function(link){return _c('n-link',{key:link.id,staticClass:"header-menu-link",attrs:{"to":link.to},nativeOn:{"click":function($event){return _vm.onNavClick(link.track_event, link.track_payload)}}},[_vm._v("\n      "+_vm._s(link.text)+"\n    ")])}),_vm._v(" "),_c('a',{staticClass:"header-menu-link",attrs:{"href":_vm.links['help'],"target":"_blank"}},[_vm._v("\n      "+_vm._s(_vm.$t('links.helpdesk'))+"\n    ")])],2),_vm._v(" "),_c('div',{staticClass:"header-nav__divider"}),_vm._v(" "),(_vm.isUserAuthorized)?_c('div',{staticClass:"header-nav__actions"},[_c('a',{staticClass:"header-action-btn",attrs:{"href":_vm.links['accounts']},on:{"click":function($event){return _vm.onNavClick('Clicked button accounts', {
          name: 'accounts',
          place: 'header site',
        })}}},[_vm._v("\n      "+_vm._s(_vm.$t('header.accounts'))+"\n    ")])]):_c('div',{staticClass:"header-nav__actions"},[_c('a',{staticClass:"header-action-link",attrs:{"href":_vm.links['sign-in']},on:{"click":function($event){return _vm.onNavClick('Clicked button sign in', {
          name: 'sign in',
          place: 'header site',
        })}}},[_vm._v("\n      "+_vm._s(_vm.$t('header.log_in'))+"\n    ")]),_vm._v(" "),_c('a',{staticClass:"header-action-btn",attrs:{"href":_vm.links['sign-up']},on:{"click":function($event){return _vm.onNavClick('Clicked button sign up', {
          name: 'sign up',
          place: 'header site',
        })}}},[_vm._v("\n      "+_vm._s(_vm.$t('header.sign_up'))+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }