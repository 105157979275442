export function isEmpty (obj) {
  return Object.values(obj).every(item => item === null)
}

export function getCookie (cname) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + cname + '=')
  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  }
}
export function setCookie ({ name, value, days = null, domain, path }) {
  let cookie = `${name}=${value}`

  days &&
    (cookie += `; expires=${new Date(
      Date.now() + days * 24 * 60 * 60 * 1000,
    )}`)

  domain && (cookie += `; domain=${domain}`)

  path && (cookie += `; path=${path}`)

  document.cookie = cookie
}
