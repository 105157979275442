import findKey from 'lodash/findKey'

export const state = () => ({
  current: 'BTC',
  quoteCurrency: 'EUR',
  coinsNameMapping: {
    bitcoin: 'BTC',
    ethereum: 'ETH',
    'tether-usd': 'USDT',
    xrp: 'XRP',
    litecoin: 'LTC',
    'usd-coin': 'USDC',
    link: 'LINK',
    dai: 'DAI',
    algo: 'ALGO',
    'bitcoin-cash': 'BCH',
    synthetix: 'SNX',
    uniswap: 'UNI',
    maker: 'MKR',
    aave: 'AAVE',
    'enjin-coin': 'ENJ',
    eos: 'EOS',
    stellar: 'XLM',
    'omg-network': 'OMG',
    zcash: 'ZEC',
    'basic-attention-token': 'BAT',
    polygon: 'MATIC',
    orchid: 'OXT',
    'the-graph': 'GRT',
    'yearn.finance': 'YFI',
    '0x': 'ZRX',
    cardano: 'ADA',
    dogecoin: 'DOGE',
    polkadot: 'DOT',
    solana: 'SOL',
    filecoin: 'FIL',
    monero: 'XMR',
    cosmos: 'ATOM',
    'ethereum-classic': 'ETC',
    kusama: 'KSM',
    decentraland: 'MANA',
    siacoin: 'SC',
    icon: 'ICX',
    nano: 'NANO',
    ankr: 'ANKR',
    flow: 'FLOW',
    tron: 'TRX',
    tezos: 'XTZ',
    uma: 'UMA',
  },

  assets: {
    loading: false,
    data: [],
  },
  asset: {
    loading: false,
    data: {},
  },
  rates: {},
})

export const mutations = {
  SET_ASSETS (state, data) {
    state.assets.data = data.filter(asset => findKey(state.coinsNameMapping, c => c === asset.ticker))
    state.assets.loading = false
  },
  SET_ASSETS_LOADING (state, loading) {
    state.assets.loading = loading
  },
  SET_ASSET (state, data) {
    state.asset.data = data
    state.asset.loading = false
  },
  SET_ASSET_LOADING (state, loading) {
    state.asset.loading = loading
  },
  SET_CURRENT (state, name) {
    state.current = name
  },
  SET_RATES (state, data) {
    state.rates = data
  },
}

export const actions = {
  async loadAssets ({ commit, dispatch }) {
    commit('SET_ASSETS_LOADING', true)
    const response = await this.$axios.$get('/api/v1/assets/EUR')
    commit('SET_ASSETS', response.result)
  },
  async loadAsset ({ commit, state, dispatch }, name) {
    if (state.current !== name) {
      commit('SET_ASSET_LOADING', true)
    }
    commit('SET_CURRENT', name)
    const response = await this.$axios.$get(`/api/v1/assets/${name}/EUR`)
    commit('SET_ASSET', response.result)
  },
  async loadRates ({ commit }) {
    const response = await this.$axios.get('/api/rates.json')
    commit('SET_RATES', response.data)
  },
}
