//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import links from '~/helpers/links'

export default {
  name: 'HeaderNav',
  props: {
    isMobileNavOpened: {
      type: Boolean,
      default: false,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      links,
      externalLinksNav: [
        // Use it for external links with <a> tag in template
      ],
      nav: [
        {
          text: this.$t('links.buy_crypto'),
          to: this.localePath('buy-cryptocurrency'),
          id: 'header-nav-buy-crypto',
          track_event: 'Clicked link buy crypto',
          track_payload: { name: 'buy crypto', place: 'header site' },
        },
        {
          text: this.$t('links.prepaid_card'),
          to: this.localePath('bitcoin-debit-card'),
          id: 'header-nav-bitcoin-debit-card',
          track_event: 'Clicked link prepaid card',
          track_payload: { name: 'prepaid card', place: 'header site' },
        },
        {
          text: this.$t('links.bitcoin_wallet'),
          to: this.localePath('bitcoin-wallet'),
          id: 'header-nav-bitcoin-wallet',
          track_event: 'Clicked link bitcoin wallet',
          track_payload: { name: 'bitcoin wallet', place: 'header site' },
        },
        {
          text: this.$t('links.partners'),
          to: this.localePath('referral-program'),
          id: 'header-nav-referral-program',
          track_event: 'Clicked link partner',
          track_payload: { name: 'partners', place: 'header site' },
        },
      ],
    }
  },
  computed: {
    isUserAuthorized () {
      return this.$store.state.isUserAuthorized
    },
    requestCompleted () {
      return this.$store.state.requestCompleted
    },
  },
  methods: {
    localePath (url) {
      return this.$i18n.path(url)
    },
    onNavClick (event, payload) {
      this.$emit('go')

      return () => {
        this.$track(event, payload)
      }
    },
  },
}
