export const state = () => ({
  locale: 'en',
  locales: ['en', 'fr', 'de'],

  isUserAuthorized: false,
  requestCompleted: false,

  fee: 0,

  'xsrf-token': '',
})

export const mutations = {
  SET_LANG (state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale
    }
  },
  AUTH_STATUS (state, bool) {
    state.isUserAuthorized = bool
  },
  REQUEST_COMPLETED (state, bool) {
    state.requestCompleted = bool
  },
  SET_FEE (state, data) {
    state.fee = data
  },
  SET_COOKIE (state, data) {
    state['xsrf-token'] = data
  },
}
