//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCookie, setCookie } from '~/helpers/util'
export default {
  data: () => ({
    show: false,
  }),

  mounted () {
    if (!getCookie('cookiebar')) {
      this.show = true
    }
  },

  methods: {
    onMoreInfo () {
      this.$router.push('/legal/cookie_policy')
    },
    onConfirm () {
      setCookie({ name: 'cookiebar', value: 'hide', days: 30 })
      this.show = false
    },
  },
}
