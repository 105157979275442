//
//
//
//
//
//
//
//
//
//
//
//
//
//

import links from '~/helpers/links'

export default {
  name: 'FilledBackground',
  data: () => ({
    links,
  }),
  methods: {
    trackClick () {
      this.$track('Clicked button', {
        name: 'Join Cryptopay',
        place:
            this.$route.name === 'index'
              ? 'start place'
              : `${this.$route.name} place`,
      })
    },
  },
}
