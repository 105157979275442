//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderLogo from './HeaderLogo'
import HeaderB2Switcher from './HeaderB2Switcher'
import HeaderHamburger from './HeaderHamburger'
import HeaderNav from './HeaderNav'
import links from '~/helpers/links'

export default {
  name: 'Header',
  components: {
    HeaderLogo,
    HeaderB2Switcher,
    HeaderHamburger,
    HeaderNav,
  },
  data: () => ({
    theme: 'dark',
    links,
    scrollPosition: 0,
    isMobileNavOpened: false,
    collapseBP: 1240,
    isCollapsed: false,
    screenWidth: 1440,
    isScrolled: false,
    themesForPages: {
      index: {
        initial: 'dark-transparent',
      },
      'bitcoin-debit-card': {
        initial: 'dark-transparent',
      },
      'buy-bitcoins': {
        initial: 'light-transparent',
      },
      'bitcoin-wallet': {
        initial: 'light-transparent',
      },
      'referral-program': {
        initial: 'dark-transparent',
      },
    },
  }),

  computed: {
    isLogoVisible () {
      if (this.isCollapsed) {
        return !this.isMobileNavOpened
      } else {
        return true
      }
    },

    isSwitcherVisible () {
      if (this.isCollapsed) {
        return this.isMobileNavOpened
      } else {
        return true
      }
    },
  },

  watch: {
    $route (route) {
      this.darkOrLightTheme(route.name)
    },
  },

  beforeMount () {
    this.screenWidth = window.innerWidth
    this.darkOrLightTheme(this.$route.name)
  },

  mounted () {
    this.onScroll()
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },

  destroyed () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    localePath (url) {
      return this.$i18n.path(url)
    },

    onResize (e) {
      const { innerWidth } = window
      const isCollapsed = innerWidth <= this.collapseBP
      const lastIsCollapsed = this.isCollapsed

      if (lastIsCollapsed && !isCollapsed && this.isMobileNavOpened) {
        this.closeMobileNav()
      }

      this.screenWidth = innerWidth
      this.isCollapsed = isCollapsed
    },

    onScroll (e) {
      const y = window.pageYOffset || document.documentElement.scrollTop
      const theme = this.themesForPages[this.$route.name]

      this.isScrolled = y > 0

      if (theme) {
        this.theme = this.isScrolled ? 'light' : theme.initial
      } else {
        this.theme = 'light'
      }
    },

    toggleMobileMenu (e) {
      if (e) {
        this.openMobileNav()
      } else {
        this.closeMobileNav()
      }
    },

    openMobileNav () {
      this.isMobileNavOpened = true
      this.setOverlow('hidden')
    },

    closeMobileNav () {
      this.isMobileNavOpened = false
      this.setOverlow('unset')
    },

    setOverlow (val) {
      const body = window.document.body

      body.style.overflow = val
    },

    darkOrLightTheme (routeName) {
      const themesForPage = this.themesForPages[routeName]

      if (!themesForPage) {
        this.theme = 'light-transparent'
      } else if (this.scrolled) {
        this.theme = themesForPage.scrolled
      } else {
        this.theme = themesForPage.initial
      }
    },
  },
}
