//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import links from '~/helpers/links'

export default {
  name: 'Footer',

  data () {
    return {
      links,
      selected: this.$store.state.locale,
      locales: [
        { value: 'en', name: 'English' },
        { value: 'de', name: 'Deutsch' },
        { value: 'fr', name: 'French' },
      ],
    }
  },

  computed: {
    getYear () {
      return new Date().getFullYear()
    },
    locale () {
      return this.$store.state.locale
    },
  },

  methods: {
    localePath (url) {
      return this.$i18n.path(url)
    },

    // chooseLang (event) {
    //   track('Select locale', { place: 'footer site', 'chosen locale': event.target.value });
    //
    //   let currentLocale = this.$store.state.locale;
    //   let chosenLocale = event.target.value;
    //   if (this.$store.state.locales.indexOf(chosenLocale) === -1) {
    //     console.log(`Trying to set not supported locale: ${chosenLocale}`);
    //     chosenLocale = this.$i18n.fallbackLocale;
    //   }
    //
    //   this.$store.commit('SET_LANG', chosenLocale);
    //   this.$i18n.locale = chosenLocale;
    //
    //   const toReplace = '^/' + currentLocale + (this.$route.fullPath.indexOf('/' + currentLocale + '/') === 0 ? '/' : '');
    //   const findLocale = new RegExp(toReplace)
    //   let path = this.$route.fullPath.replace(findLocale, '/');
    //   this.$router.push(`/${chosenLocale}${path}`);
    // }
  },
}
