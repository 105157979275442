function cpaCookies (networkName, values, attributionWindow) {
  const date = new Date()
  const shift = attributionWindow * 24 * 60 * 60 * 1000
  date.setTime(date.getTime() + shift)
  const expires = '; expires=' + date.toUTCString()
  const expiresAt = Date.now() + shift

  const currentDate = Date.now()
  const tail = `; Domain=.cryptopay.me; path=/; Expires=${expires}`

  document.cookie = networkName + '=' + values.cpa_cookie + tail
  document.cookie = networkName + '_set_at=' + currentDate + tail
  document.cookie = networkName + '_expires_at=' + expiresAt + tail
}

function cpaProcessor () {
  const cpaSettings = {
    advendor: {
      attributionWindow: 30,
      linkage: {
        utm_source: 'Advendor',
      },
      params: {
        cpa_cookie: 'afclick',
      },
    },
    clickdealer: {
      attributionWindow: 30,
      linkage: {
        utm_source: 'clickdealer',
      },
      params: {
        cpa_cookie: 'clickdealer',
      },
    },
    actionpay: {
      attributionWindow: 30,
      linkage: {
        utm_source: 'actionpay',
      },
      params: {
        cpa_cookie: 'actionpay',
      },
    },
    cityads: {
      attributionWindow: 30,
      linkage: {
        utm_source: 'cityads_1',
      },
      params: {
        cpa_cookie: 'click_id',
      },
    },
  }

  const getParams = {}
  window.location.search
    .replace('?', '')
    .split('&')
    .forEach((p) => {
      const [k, v] = p.split('=')
      getParams[k] = v
    })

  Object.keys(cpaSettings).forEach((cpaName) => {
    const cpaCandidate = cpaSettings[cpaName]
    let hit = true
    Object.keys(cpaCandidate.linkage).forEach((paramName) => {
      const paramValue = cpaCandidate.linkage[paramName]
      hit = hit && getParams[paramName] === paramValue
    })

    const toSave = {}
    if (hit) {
      Object.keys(cpaCandidate.params).forEach((paramName) => {
        const value = getParams[cpaCandidate.params[paramName]]
        hit = hit && value !== undefined
        toSave[paramName] = value
      })
      if (hit) {
        cpaCookies(cpaName, toSave, cpaCandidate.attributionWindow)
      }
    }
  })
}
cpaProcessor()
