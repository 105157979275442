//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable */
  import('intersection-observer');

  import { getCookie } from '~/helpers/util';
  import { segment } from '~/plugins/segment';
  import VLoading from '~/components/Loading';
  import VHeader from '~/components/Header';
  import VFooter from '~/components/Footer';
  import FilledBackground from '~/components/FilledBackground';
  import CookieMessageGDPR from '~/components/CookieMessageGDPR';
  /* eslint-enable */

export default {
  components: {
    VLoading,
    VHeader,
    VFooter,
    FilledBackground,
    CookieMessageGDPR,
  },
  data () {
    return {
      ready: false,
      timeout: null,
      isCookiebotEnabled: process.env.COOKIEBOT_ENABLED,
    }
  },
  head () {
    const SITE_URL = 'https://cryptopay.me'
    const canonical = `${SITE_URL}${this.$route.path}`

    const link = [{ rel: 'canonical', href: canonical }]

    // TODO remove after adding of i18n
    //
    // let LANG_URL = this.$i18n.locale !== 'en' ? this.$route.path.slice(3) : this.$route.path
    //
    // const locales = Object.keys(this.$i18n.messages)
    //
    // locales.forEach(lang => {
    //   let params = {
    //     rel: 'alternate',
    //     hreflang: lang,
    //     href: `${SITE_URL}${LANG_URL}`
    //   }
    //
    //   if (lang !== 'en') params.href = `${SITE_URL}/${lang}${LANG_URL}`
    //
    //   link.push(params)
    // })

    return {
      htmlAttrs: { lang: this.$t('lang') },
      link,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.ready = true
    })

    try {
      segment()
    } catch (error) {
      console.error({ error })
    }

    try {
      this.initAuth()
    } catch (error) {
      console.error({ error })
    }
  },
  destroyed () {
    //
  },
  methods: {
    initAuth () {
      this.$store.commit('SET_COOKIE', getCookie('XSRF-TOKEN'))
      this.$store.commit('AUTH_STATUS', false)
      this.$axios
        .get('/user.json')
        .then((response) => {
          if (response.data.session_expired_at) {
            this.$store.commit('AUTH_STATUS', true)
          }
        })
        .catch(() => {
          this.$store.commit('AUTH_STATUS', false)
        })
        .finally(() => {
          this.$store.commit('REQUEST_COMPLETED', true)
        })
    },
  },
}
