//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HeaderHamburger',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    ariaPressed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle () {
      this.$emit('toggle', !this.opened)
    },
  },
}
