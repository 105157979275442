import castArray from 'lodash/castArray'
import flattenDeep from 'lodash/flattenDeep'

function unJSON (value) {
  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}
function parseTokens (token) {
  return flattenDeep(castArray(unJSON(token)))
}

function loadSegment (key, options) {
  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || [])

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ]

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments)
      args.unshift(method)
      analytics.push(args)
      return analytics
    }
  }

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i]
    analytics[key] = analytics.factory(key)
  }

  return {
    stub: analytics,
    load () {
      return new Promise((resolve) => {
        // Create an async script element based on your key.
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src =
          'https://cdn.segment.com/analytics.js/v1/' +
          key +
          '/analytics.min.js'

        // Insert our script next to the first script element.
        const first = document.getElementsByTagName('script')[0]
        first.parentNode.insertBefore(script, first)
        analytics._loadOptions = options

        script.onload = function () {
          const a = window.analytics
          delete window.analytics

          resolve(a)
        }
      })
    },
  }
}

const instances = []
let loadPromise = Promise.resolve()
const multiAnalytics = {
  load (tokenLike) {
    parseTokens(tokenLike).forEach((t) => {
      const { stub, load } = loadSegment(t)
      instances.push(stub)

      const index = instances.length
      loadPromise = loadPromise
        .then(() => load())
        .then((a) => {
          instances[index] = a
        })
    })
  },
  async ready (...args) {
    await loadPromise
    instances.forEach((a) => {
      a.ready(...args)
    })
  },
  async page (...args) {
    await loadPromise
    instances.forEach((a) => {
      a.page(...args)
    })
  },
  async identify (...args) {
    await loadPromise
    instances.forEach((a) => {
      a.identify(...args)
    })
  },
  async track (...args) {
    await loadPromise
    instances.forEach((a) => {
      a.track(...args)
    })
  },
}

export default multiAnalytics
