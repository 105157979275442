import Vue from 'vue'
import multiAnalytics from '~/helpers/multiAnalytics'

// NOTE for testing
//
// eslint-disable-next-line quotes
// const segmentToken = "[\"BDARxGf2Pv638WE5oRYw9oaxC0bU3kSg\",\"TRty08dSQQ4YyqSZJfFYJ1vrlwf15e3V\"]"

const segmentToken = process.env.SEGMENT
const analytics = multiAnalytics

export async function segment () {
  if (!segmentToken) {
    return
  }

  await multiAnalytics.load(segmentToken)
}

export function page (category, name) {
  if (!segmentToken || !analytics) {
    return
  }

  analytics.page(`Cryptopay.me | ${category}`, name)
}

export function identify (userId, traits) {
  if (!segmentToken || !analytics) {
    return
  }

  analytics.identify(userId, traits)
}

export function track (event, { ...options }) {
  if (!segmentToken || !analytics) {
    return
  }

  analytics.track(event, options, {
    integrations: {
      All: true,
      Intercom: false,
    },
  })
}

Vue.prototype.$page = page
Vue.prototype.$track = track
