import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2b23135a&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=2b23135a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b23135a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FilledBackground: require('/var/www/html/components/FilledBackground.vue').default,CookieMessageGDPR: require('/var/www/html/components/CookieMessageGDPR/index.vue').default})
